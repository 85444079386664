<template>
  <div class="admin-invite-users">
    <loading-screen :is-loading="isSaving"></loading-screen>

    <!-- Page Header -->
    <div class="page-header">
      <h1 class="page-title">
        Invite New Users to {{ selectedOrganisation["displayName"] }}
      </h1>
      <div class="actions">
        <!-- <a-button
          class="button-margin-left btn-rounded"
          icon="arrow-left"
          size="large"
          type="primary"
          @click.prevent="goBack"
          >Back</a-button
        > -->
      </div>
    </div>
    <!-- / Page Header -->

    <!-- Form -->
    <h3>User Details</h3>
    <div class="form form-margin-bottom">
      <!-- User Row -->
      <a-row :gutter="20" class="user-row" v-for="(user, i) in users" :key="i">
        <a-col :span="7">
          <a-input
            size="large"
            placeholder="First Name"
            v-model="user.firstName"
          ></a-input>
        </a-col>
        <a-col :span="7">
          <a-input
            size="large"
            placeholder="Surname"
            v-model="user.surname"
          ></a-input>
        </a-col>
        <a-col :span="users.length > 1 ? 9 : 10">
          <a-input
            size="large"
            placeholder="Email"
            v-model="user.email"
          ></a-input>
        </a-col>
        <a-col :span="1" v-if="users.length > 1">
          <a-popconfirm
            title="Are you sure?"
            ok-text="Yes"
            cancel-text="No"
            @confirm="removeUser(i)"
          >
            <a-button type="danger" icon="delete" size="large"></a-button>
          </a-popconfirm>
        </a-col>
      </a-row>
      <!-- / User Row -->

      <a-button @click.prevent="addUser" icon="plus">Add</a-button>
    </div>
    <!-- / Form -->

    <!-- Form -->
    <h3>Options</h3>
    <div class="form">
      <a-checkbox v-model="sendInviteEmails">Send Invite Emails</a-checkbox>
    </div>
    <!-- / Form -->

    <a-button
      @click.prevent="attemptSave"
      :disabled="isSaving"
      class="btn-rounded save-button"
      size="large"
      type="primary"
    >
      {{ sendInviteEmails ? "Send Invites" : "Add Users" }}
    </a-button>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import LoadingScreen from "../../../components/LoadingScreen.vue";
import presences from "../../../api/presences";
const _ = require("lodash");
import axios from "axios";
export default {
  data() {
    return {
      isSaving: false,
      users: [
        {
          firstName: "",
          surname: "",
          email: "",
        },
      ],
      sendInviteEmails: true,
    };
  },

  components: { LoadingScreen },

  computed: {
    ...mapGetters("admin", {
      selectedOrganisation: "selectedOrganisation",
      tenantId: "tenantId",
    }),
  },

  created() {
    if (!this.selectedOrganisation) {
      this.$message.info("Please select an organisation");
      this.$router.push("/admin");
    }
  },

  methods: {
    goBack() {
      this.$router.push("/admin/users");
    },

    removeUser(i) {
      this.users.splice(i, 1);
    },

    addUser() {
      this.users.push({
        firstName: "",
        lastName: "",
        email: "",
      });
    },

    validateEmail(email) {
      var re = /\S+@\S+\.\S+/;
      return re.test(email);
    },

    getValidationErrors() {
      let errors = [];
      this.users.forEach((user) => {
        if (
          !user.firstName.trim().length ||
          !user.surname.trim().length ||
          !user.email.trim().length
        ) {
          errors.push("Please complete all fields");
        }
        if (!this.validateEmail(user.email)) {
          errors.push(user.email + " is not a valid email");
        }
      });
      return errors;
    },

    attemptSave() {
      let vm = this;

      // Validation
      let validationErrors = this.getValidationErrors();
      if (validationErrors.length) {
        this.$message.error(validationErrors[0]);
        return false;
      }

      vm.save();
    },

    getAddPresenceRequestParams(user) {
      let vm = this;
      return {
        Presence: {
          FirstName: user.firstName,
          Surname: user.surname,
          Email: user.email,
          OwnerId: vm.selectedOrganisation.id
        },
        SendInviteEmail: vm.sendInviteEmails,
        MakeSosResponder: false
      };
    },

    save() {
      let vm = this;
      vm.isSaving = true;

      let requests = [];
      _.each(this.users, (user) => {
        requests.push(
          presences.addPresence(
            this.tenantId,
            this.getAddPresenceRequestParams(user)
          )
        );
      });
      axios
        .all(requests)
        .then(() => {
          vm.isSaving = false;
          vm.$message.success("Invites sent successfully");
          // Reset the page
          this.users = [
            {
              firstName: "",
              surname: "",
              email: "",
            },
          ];
          this.sendInviteEmails = true;
        })
        .catch((e) => {
          vm.isSaving = false;
          console.log(e);
          let errors;
          if (e.response && e.response.data && typeof e.response.data === "object") {
            errors = _.flatten(_.toArray(e.response.data.errors));
          } else {
            errors = ["Something went wrong. Please try again."];
          }
          vm.$message.error(_.first(errors));
        });
    },
  },
};
</script>

<style scoped lang="scss">
.admin-invite-users {
  .form {
    background: #fff;
    padding: 20px;
    border-radius: 6px;
    font-weight: 500;
    border: 1px solid #fff;

    &.form-margin-bottom {
      margin-bottom: 30px;
    }
  }

  .button-margin-left {
    margin-left: 10px;
  }

  .save-button {
    margin-top: 30px;
  }

  .user-row {
    margin-bottom: 20px;
  }
}
</style>